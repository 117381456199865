//Variables to replace
const poolId = 'eu-west-1_QV7UzykxQ'
const clientId = '47ospnk94n27n0988e3shf0ngl'
const api = 'https://r02tgr7f48.execute-api.eu-west-1.amazonaws.com/Prod'

const Config = {
    UserPoolId: poolId,
    AppClientId: clientId,
    ApiURL: api + '/notes'
}

export default Config